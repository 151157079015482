import React, { CSSProperties, useState } from "react"

import { MinusIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid"
import { twMerge } from "tailwind-merge"

import { Bee } from "~/src/components/BeeKit"
import { type Range } from "~/src/types/filter"

const DEFAULT_MINIMUM_QUANTITIES: Range[] = [
  { label: "< 10", min: 1, max: 10 },
  { min: 11, max: 50 },
  { min: 51, max: 100 },
  { min: 101, max: 150 },
  { min: 151, max: 500 },
  { min: 501, max: 1000 },
  { label: "1001+", min: 1001, max: 1000000 },
]

export type FilterValues = {
  categories: string[]
  productFeatures: string[]
  minimumQuantities: Range[]
  priceRange: Range
}

export type CatalogFilterProps = {
  open?: boolean
  numSelectedFilters?: number
  disabled?: boolean
  className?: string
  values: FilterValues
  priceRangeBounds?: Range
  options?: { categories: string[]; productFeatures: string[] }
  onChange?: (values: FilterValues) => void
  onApply?: (values: FilterValues) => void
  onClearAll?: () => void
  onClose?: () => void
}

type CatalogFilterInternalProps = Required<
  Pick<CatalogFilterProps, "open" | "disabled" | "options" | "priceRangeBounds" | "numSelectedFilters" | "values">
> &
  Pick<CatalogFilterProps, "className" | "values" | "onChange" | "onClearAll" | "onClose"> & {
    onSelectFilter: (key: string, item: FilterItem) => void
    onApply: () => void
  } & {
    expandedSections: Record<string, boolean>
    onSectionToggled: (key: string) => void
  }

type FilterItem = string | Range
type FilterSectionProps = {
  title: string
  items: FilterItem[]
  selectedItems: FilterItem[]
  open: boolean
  checkBy?: (currentItem: FilterItem, selectedItems: FilterItem[]) => boolean
  setSelectedItems: (item: FilterItem) => void
  onToggle: () => void
}

type FilterSectionsProps = Required<
  Pick<CatalogFilterInternalProps, "options" | "values" | "priceRangeBounds" | "onSelectFilter" | "onChange">
> & {
  expandedSections: Record<string, boolean>
  onSectionToggled: (key: string) => void
}

function FilterSection(props: FilterSectionProps) {
  const { open, title, items, selectedItems, setSelectedItems, onToggle, checkBy = (x, ys) => ys.includes(x) } = props

  return (
    <div className="py-2 border-t">
      <div
        className="flex list-none justify-between items-center hover:cursor-pointer text-gray-800"
        onClick={onToggle}
      >
        <h3 className="font-semibold">{title}</h3>
        {open ? <MinusIcon className="w-4 h-4 text-gray-600" /> : <PlusIcon className="w-4 h-4 text-gray-600" />}
      </div>

      <div className={open ? "visible" : "pt-2 hidden"}>
        {items.map((item, index) => {
          const label = typeof item === "string" ? item : item.label ? item.label : `${item.min}-${item.max}`
          return (
            <div key={label || index} className="py-1">
              <label className="flex items-center text-gray-800">
                <input
                  type="checkbox"
                  checked={checkBy(item, selectedItems)}
                  onChange={() => setSelectedItems(item)}
                  className="mr-2 rounded border-gray-300 checked:bg-blue-900	hover:bg-blue-100"
                />
                {label}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function FilterSections({
  options,
  values,
  priceRangeBounds,
  expandedSections,
  onSectionToggled,
  onSelectFilter,
  onChange,
}: FilterSectionsProps) {
  const selectedCategories = values.categories
  const selectedProductFeatures = values.productFeatures
  const selectedMinimumQuantities = values.minimumQuantities
  const selectedPriceRange = values.priceRange

  return (
    <>
      {options.categories.length > 0 && (
        <FilterSection
          open={expandedSections.categories}
          title="Category"
          items={options.categories}
          selectedItems={selectedCategories}
          setSelectedItems={(item) => onSelectFilter("categories", item)}
          onToggle={() => onSectionToggled("categories")}
        />
      )}
      {options.productFeatures.length > 0 && (
        <FilterSection
          open={expandedSections.productFeatures}
          title="Product Features"
          items={options.productFeatures}
          selectedItems={selectedProductFeatures}
          setSelectedItems={(item) => onSelectFilter("productFeatures", item)}
          onToggle={() => onSectionToggled("productFeatures")}
        />
      )}
      <FilterSection
        open={expandedSections.minimumQuantities}
        title="Minimum Quantity"
        items={DEFAULT_MINIMUM_QUANTITIES}
        selectedItems={selectedMinimumQuantities}
        setSelectedItems={(item) => onSelectFilter("minimumQuantities", item)}
        checkBy={(currentItem: Range, selectedItems: Range[]) =>
          selectedItems.some(({ min, max }) => min === currentItem.min && max === currentItem.max)
        }
        onToggle={() => onSectionToggled("minimumQuantities")}
      />
      <div className="py-2 border-t">
        <div
          className="flex list-none place-content-between items-center hover:cursor-pointer text-gray-800"
          onClick={() => onSectionToggled("priceRange")}
        >
          <h3 className="font-semibold">Lowest Unit Price</h3>
          {expandedSections.priceRange ? (
            <MinusIcon className="w-4 h-4 text-gray-600" />
          ) : (
            <PlusIcon className="w-4 h-4 text-gray-600" />
          )}
        </div>
        <div className={twMerge("flex flex-col pt-4 gap-2", expandedSections.priceRange ? "visible" : "pt-2 hidden")}>
          <div className="flex justify-between text-xs text-white text-center">
            <span className="rounded-sm bg-gray-600 p-2 min-w-xs py-px px-1">
              ${Math.floor(selectedPriceRange.min)}
            </span>
            <span className="rounded-sm bg-gray-600 p-2 min-w-xs py-px px-1">${Math.ceil(selectedPriceRange.max)}</span>
          </div>
          <Bee.RangeInput
            min={priceRangeBounds.min}
            max={priceRangeBounds.max}
            left={{ value: Math.floor(selectedPriceRange.min) }}
            right={{ value: Math.ceil(selectedPriceRange.max) }}
            onChange={(item) => onChange?.({ ...values, priceRange: { min: item[0], max: item[1] } as Range })}
            className="w-7/8"
          />
        </div>
      </div>
    </>
  )
}

function CatalogFilterMobile(props: CatalogFilterInternalProps) {
  const {
    disabled,
    numSelectedFilters,
    options,
    priceRangeBounds,
    values,
    expandedSections,
    onSectionToggled,
    onSelectFilter,
    onChange,
    onApply,
    onClearAll,
    onClose,
  } = props

  return (
    <div className={twMerge("flex flex-col sm:hidden fixed inset-0 overflow-y-auto bg-white z-50")}>
      <div className="flex justify-between px-4 py-3.5">
        <div className="flex gap-3">
          <h1 className="text-lg font-bold">Filter {numSelectedFilters > 0 ? `(${numSelectedFilters})` : ""}</h1>
          <button className="text-xs text-gray-500 hover:cursor-pointer" onClick={onClearAll}>
            Clear all
          </button>
        </div>
        <XMarkIcon onClick={onClose} className="w-6 h-6 text-gray-600 ml-1 hover:cursor-pointer" />
      </div>
      <div className="px-6 pb-6">
        <FilterSections
          options={options}
          values={values}
          expandedSections={expandedSections}
          priceRangeBounds={priceRangeBounds}
          onSelectFilter={onSelectFilter}
          onChange={(values) => onChange?.(values)}
          onSectionToggled={onSectionToggled}
        />
        <Bee.Button
          onClick={() => {
            onApply()
            onClose?.()
          }}
          className="px-2 pb-2 w-full cursor-pointer disabled:cursor-not-allowed"
          disabled={disabled}
        >
          Apply
        </Bee.Button>
      </div>
    </div>
  )
}

function CatalogFilterDesktop(props: CatalogFilterInternalProps) {
  const {
    disabled,
    className,
    options,
    priceRangeBounds,
    expandedSections,
    values,
    onSectionToggled,
    onSelectFilter,
    onChange,
    onApply,
  } = props

  return (
    <div
      className={twMerge("hidden sm:flex flex-col gap-4 pr-2")}
      style={{ "--catalog-filter-width": `${CatalogFilter.width}px` } as CSSProperties}
    >
      <div className={twMerge("h-full w-[var(--catalog-filter-width)] bg-grey-50", className)}>
        <div className="px-1 pb-2">
          <FilterSections
            options={options}
            values={values}
            expandedSections={expandedSections}
            priceRangeBounds={priceRangeBounds}
            onSelectFilter={onSelectFilter}
            onChange={(values) => onChange?.(values)}
            onSectionToggled={onSectionToggled}
          />
        </div>
        <Bee.Button
          onClick={onApply}
          className={twMerge(
            "sticky bottom-3 left-0 z-20 px-2 pb-2 w-[300px]",
            disabled ? "cursor-not-allowed" : "cursor-pointer"
          )}
          disabled={disabled}
        >
          Apply
        </Bee.Button>
      </div>
    </div>
  )
}

//region Component
export function CatalogFilter(props: CatalogFilterProps) {
  const {
    open = true,
    disabled = false,
    numSelectedFilters = 0,
    options = { categories: [], productFeatures: [] },
    priceRangeBounds = { min: 0, max: 1000 },
    values,
    onChange,
    onApply,
  } = props

  const [expandedSections, setExpandedSections] = useState({
    categories: true,
    productFeatures: true,
    minimumQuantities: true,
    priceRange: true,
  })

  const handleToggleSection = (key: string) => {
    setExpandedSections((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  const handleOnSelectFilter = (key: string, item: FilterItem) => {
    if (onChange) {
      const oldFilterSectionValues = values[key]
      const newFilterSectionValues = oldFilterSectionValues.some((i: FilterItem) => itemsAreEqual(i, item))
        ? oldFilterSectionValues.filter((i: FilterItem) => !itemsAreEqual(i, item))
        : [...oldFilterSectionValues, item]

      onChange({
        ...values,
        [key]: newFilterSectionValues,
      })
    }
  }

  const handleOnApply = () => {
    onApply?.(values)
  }

  const propsWithDefaults = {
    open,
    disabled,
    expandedSections,
    numSelectedFilters,
    options,
    priceRangeBounds,
    values,
    onApply: handleOnApply,
    onSelectFilter: handleOnSelectFilter,
  }

  return (
    open && (
      <>
        <CatalogFilterDesktop {...props} {...propsWithDefaults} onSectionToggled={handleToggleSection} />
        <CatalogFilterMobile {...props} {...propsWithDefaults} onSectionToggled={handleToggleSection} />
      </>
    )
  )
}
//endregion

function itemsAreEqual(item: FilterItem, other: FilterItem) {
  if (isRange(item) && isRange(other)) {
    return item.min === other.min && item.max === other.max
  } else {
    return item === other
  }
}

function isRange(item: FilterItem): item is Range {
  return typeof item !== "string" && "min" in item && "max" in item
}

CatalogFilter.width = 300
